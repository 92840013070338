body {
    font-family: 'Arial', sans-serif;
    background-color: #3a3939;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

html, body {
    height: 100%;
}

main {
    flex: 1;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.footer {
    background: linear-gradient(rgba(50,50,50,1), rgba(34,34,34,1));
    color: white;
    padding: 20px 0;
    text-align: center;
    position: relative;
    bottom: 0;
    flex-shrink: 0;
    width: 100%;
}

.main-content {
    padding: 40px 0;
}

.contact-form {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.contact-form input, 
.contact-form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(213, 73, 73, 0.1);
}

#resume, #projects, #about, #background, #contact {
    background: linear-gradient(rgb(149, 43, 43), rgb(0, 0, 0));    padding: 20px;
    color: rgba(255, 255, 255, 0.933);
    margin-bottom: 25px;
    border-radius: 5px;
    border: 1px solid #efef4c;
    box-shadow: 11px 10px 20px rgba(243, 233, 88, 0.335);
}

.about :hover, .projects :hover, .resume :hover, .contact :hover, .background :hover {
    transition: transform .5s;
    transform: scale(1.009);
}

.app-content {
    flex: 1 0 auto;
    margin-bottom: 20px;
}

.background {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin-bottom: 20px;
}

.work-item {
    border: 1px solid #efef4c;
    padding: 10px;
    margin-bottom: 20px;
}

.work-item h3 {
    margin-top: 0;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.project-item {
    flex-basis: calc(33.33% - 20px);
    box-shadow: 0 4px 8px rgba(255, 238, 0, 0.335);
    border-radius: 5px;
    overflow: hidden;
    transition: transform .2s;
    max-width: 500px;
    margin: 0 auto;
}

.project-item:hover {
    transform: scale(1.05);
}

.project-item img {
    width: 100%;
    height: auto;
}

.project-item p {
    padding: 15px;
    font-size: 15px;
}

.nav-link {
    cursor: pointer !important;
    transition: color 0.2s;
}

.nav-link:hover {
    color: rgba(183, 26, 26, 0.822) !important;
}

.navbar {
    height: 110px;
    line-height: 110px;
    background: linear-gradient(rgba(50,50,50,1), rgba(34,34,34,1));
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}

.navbar .nav-link, .navbar .navbar-brand {
    line-height: 110px;
    transition: color 0.2s;
}

.navbar .navbar-brand:hover {
    color: rgba(183, 26, 26, 0.822) !important;
}

.dropdown-menu {
    background-color: rgba(34,34,34,1) !important;
    border: none;
}

.dropdown-item {
    transition: color 0.2s !important;
    color: #ffffff !important;

}

.dropdown-item:hover {
    color: #ca1515 !important;
    transition: color 0.2s !important;
}

.navbar-toggler-icon {
    filter: brightness(0) invert(1);
    color: #ffffff !important;
}

nav > a, nav > button, nav > div > ul > li > a {
    color: #ffffff;
    text-decoration: none;
}

main {
    background: linear-gradient(rgb(54, 52, 38), rgb(25, 25, 25));
}